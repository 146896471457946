const css = require("./css/style.css");
import moveTop from "./js/move-top.js";

var hasOwnProp = Object.prototype.hasOwnProperty;
var brands = {
  IPHONE: "IPHONE|IPAD|IPOD|IOS",
  OPPO: "OPPO",
  VIVO: "VIVO",
  HONOR: "HONOR",
  HUAWEI: "HUAWEI",
  XIAOMI: "XIAOMI|REDMI",
  360: "1801-A01|1707-A01|1509-A00",
  SAMSUNG: "SAMSUNG",
};
var userAgent = window.navigator.userAgent.toUpperCase();
var is_weixin = (function () {
  if (userAgent.match(/MicroMessenger/i) == "MICROMESSENGER") {
    return true;
  } else {
    return false;
  }
})();
var is_dingtalk = (function () {
  if (userAgent.match(/DingTalk/i) == "DINGTALK") {
    return true;
  } else {
    return false;
  }
})();

for (var key in brands) {
  if (hasOwnProp.call(brands, key)) {
    brands[key] = new RegExp(brands[key], "i");
  }
}

/**
 * 获取系统名称，安卓或者ios
 */
function getOS() {
  if (userAgent.indexOf("ANDROID") > -1) {
    return "ANDROID";
  } else if (userAgent.indexOf("IPHONE") > -1) {
    return "IOS";
  }
}

/**
 * 获取设备品牌
 */
function getBrand() {
  var result = [];
  for (var key in brands) {
    if (hasOwnProp.call(brands, key)) {
      if (brands[key].test(userAgent)) {
        result.push(key);
      }
    }
  }
  return result[0];
}
var os = getOS();
var brand = getBrand();
function downloadApp(brand) {
  if (os === "IOS") {
    window.location.href = "https://itunes.apple.com/cn/app/id1557798072";
    return;
  } else {
    window.open("http://download.handcraft.ren/handcraft.apk", "_blank");
    return;
  }
  // if (is_weixin || is_dingtalk) {
  //   window.location.href =
  //     "https://a.app.qq.com/o/simple.jsp?pkgname=com.handcraft"; //从微信打开的话，用腾讯应用宝下载
  //   //  document.querySelector("#tip").style.display = "block";
  //   return;
  // }
  switch (brand) {
    case "HONOR":
      window.location.href =
        "intent://details?id=com.handcraft#Intent;package=com.huawei.appmarket;scheme=market;end;";
      break;
    case "HUAWEI":
    case "OPPO":
      window.location.href = "market://details?id=com.handcraft";
      break;
    case "XIAOMI":
      window.location =
        "intent://details?id=com.handcraft#Intent;package=com.xiaomi.market;scheme=market;end;";
      break;
    case "VIVO":
      window.location.href =
        "intent://details?id=com.handcraft#Intent;package=com.bbk.appstore;scheme=market;end;";
      break;
    // case "SAMSUNG":
    //    window.location.href = "http://www.samsungapps.com/appquery/appDetail.as?appId=apk的包名";
    //    break;
    case "360":
      window.location.href =
        "intent://details?id=com.handcraft#Intent;package=com.qihoo.appstore;scheme=market;end;";
      break;
    default:
      // window.open(
      //   "https://server.m.pp.cn/download/apk?appId=8187867&custom=0&ch_src=pp_dev&ch=default",
      //   "_blank"
      // );
      // window.open('https://gdown.baidu.com/appcenter/pkg/upload/bf7ee550c3cfee1a1fb7ef2a277896d1',"_blank");
      window.open("http://download.handcraft.ren/handcraft.apk", "_blank");
      break;
  }
}

$(document).ready(function () {
  //优化iframe 加载
  $("#about").attr("src", "./about.html");
  $("#download").click(function () {
    downloadApp(brand);
  });
  $().UItoTop({ easingType: "easeOutQuart" });
  $("#slider").responsiveSlides({
    auto: true,
    speed: 500,
    namespace: "callbacks",
    pager: true,
  });
  $(".scroll").click(function (event) {
    event.preventDefault();
    $("html,body").animate({ scrollTop: $(this.hash).offset().top }, 1200);
  });
  //图片懒加载
  $("img.lazy").lazyload({ effect: "fadeIn" });
});
export default { css, moveTop };

var arry = [
  '<div class="header">',
  '  <div class="container">',
  '    <div class="logo">',
  '      <a href="index.html">',
  '        <img alt="飨阅" src="images/logo.png" />',
  "      </a>",
  "      <p>",
  '        <a href="privacy.html" target="_blank">',
  "          隐私政策",
  "        </a>",
  '        <a href="protocal.html" target="_blank">',
  "          用户使用协议",
  "        </a>",
  "      </p>",
  "    </div>",
  '    <div class="header-info">',
  "      <h1>飨阅</h1>",
  "      <p>我想写一封信给你</p>",
  '      <div class="buttons">',
  '        <a id="download" class="button android" href="javascript:void(0)">',
  "          立即下载",
  "        </a>",
  "      </div>",
  "    </div>",
  "  </div>",
  "</div>,",
];
